import React from "react";
import { useTheme } from "styled-components";
import { EIconNames, ETextVariant } from "../types";
import { BigSection, Text } from "../components";
import { colors } from "../theme";

interface Props {
  color?: string;
}
const SubHeader: React.FC<Props> = ({ color = colors.primary }) => {
  const theme = useTheme();
  return (
    <BigSection color={color} icon={EIconNames.Clock}>
      <Text variant={ETextVariant.Title3} color={theme.colors.primary}>
        Bienvenue sur la page de règlement de votre cotisation
      </Text>
      <Text variant={ETextVariant.Body1}>
        Nous vous invitons à vérifier les informations ci-dessous, le montant de
        votre cotisation étant basé sur ces dernières.
        <br />
        Pour toute question, merci de nous contacter via notre&nbsp;
        <a href="https://www.cncef.org/contact/">formulaire de contact</a>.
      </Text>
    </BigSection>
  );
};

export default SubHeader;
