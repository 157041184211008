import React, { useEffect, useMemo, useState } from "react";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import api from "../config/api";
import {
  Icon,
  Box,
  Button,
  Loader,
  Text,
  Header,
  Section,
  Container,
} from "../components";
import { SubHeader, PaymentForm } from "../containers";
import colors from "../theme/colors";
import {
  ButtonVariant,
  EContainerVariant,
  EIconNames,
  ETextVariant,
  IProfile,
} from "../types";
import { getStripePublicKey } from "../helpers/stripe";

const Home = () => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [fetchError, setFetchError] = useState<boolean>(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | null>(
    null
  );
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const query = useMemo(() => new URLSearchParams(window.location.search), []);

  const checkPageParams = () => {
    const token = query.get("t");
    const redirectPath = query.get("redirect_to");

    if (token) {
      // fetch entry and set initialvalues
      fetchPaymentIntent(token);
    } else {
      setFetchError(true);
    }

    if (redirectPath) {
      const baseUrl = process.env.REACT_APP_PUBLIC_URL || "";
      if (baseUrl) {
        setRedirectUrl(baseUrl + redirectPath);
      }
    }
  };

  const fetchPaymentIntent = (id: string) => {
    setIsLoading(true);
    api
      .initPayment({ id })
      .then((res) => {
        const {
          success = false,
          has_to_pay = false,
          data = {},
          secret = null,
        } = res?.data;
        if (success) {
          setProfile(data);
          if (has_to_pay) {
            setClientSecret(secret);
          }
          if (data.entity_denomination) {
            const stripe_pk = getStripePublicKey(data.entity_denomination);
            if (stripe_pk) {
              setStripePromise(loadStripe(stripe_pk));
            }
          }
        } else {
          setFetchError(true);
        }
      })
      .catch(() => {
        setFetchError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRedirect = () => {
    if (redirectUrl) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 5000);
    }
  };

  useEffect(() => {
    checkPageParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPaymentSuccess) {
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentSuccess]);

  return (
    <>
      <Header color={colors.primary}>Réglement de votre cotisation</Header>
      <SubHeader />
      <Section>
        {isPaymentSuccess ? (
          <Container variant={EContainerVariant.Small}>
            <Icon name={EIconNames.CircleCheck} width={40} height={40} />
            <br />
            <br />
            <Text variant={ETextVariant.Title4}>Merci</Text>
            <Text>Votre paiement a été accepté.</Text>
            {redirectUrl && (
              <>
                <Text>
                  Vous allez être redirigé vers la page de confirmation dans 5
                  secondes. Si ce n'est pas le cas, vous pouvez cliquer sur le
                  bouton ci-dessous.
                </Text>
                <Button
                  variant={ButtonVariant.Primary}
                  onClick={() => {
                    window.location.href = redirectUrl;
                  }}
                >
                  Revenir sur cncef.org
                </Button>
              </>
            )}
          </Container>
        ) : isLoading ? (
          <Container variant={EContainerVariant.Small}>
            <br />
            <Box jc="center">{isLoading && <Loader />}</Box>
            <Text center variant={ETextVariant.Body2}>
              Récupération de vos informations...
            </Text>
          </Container>
        ) : profile && stripePromise ? (
          <Elements stripe={stripePromise}>
            <PaymentForm
              profile={profile}
              paymentErrorMessage={paymentErrorMessage}
              setPaymentErrorMessage={setPaymentErrorMessage}
              isPaymentSuccess={isPaymentSuccess}
              setIsPaymentSuccess={setIsPaymentSuccess}
              clientSecret={clientSecret}
            />
          </Elements>
        ) : (
          fetchError && (
            <Container variant={EContainerVariant.Small}>
              <Text variant={ETextVariant.Title4} color={colors.red}>
                Une erreur est survenue.
              </Text>
              <Text color={colors.red}>
                Nous n'avons pas pu récupérer vos informations. Merci de
                réessayer d'accéder à cette page depuis le lien contenu dans
                l'email que vous avez reçu.
              </Text>
            </Container>
          )
        )}
      </Section>
    </>
  );
};

Home.propTypes = {};

export default Home;
